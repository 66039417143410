import React, { useCallback } from "react";

import "./index.css"

const MarkdownTOC = props => {
  const { markdownRemark } = props;
  const { tableOfContents } = markdownRemark;

  const renderItem = useCallback((item, i) => {

    return (
      <li>
        <a href="#public-api-permissions" className="text-gray-700 hover:text-cornflower text-sm block py-1">Public API Permissions</a>
      </li>
    );
  }, []);

  return (
    <div className="hidden lg:block xl:text-sm flex-none w-64 pl-8 mr-8 h-full sticky top-12 py-12">
      <p className="py-2 text-primary-300 uppercase tracking-wider font-medium text-sm lg:text-xs">TOC</p>
      <div
        className="docs-toc"
        dangerouslySetInnerHTML={{ __html: tableOfContents }}
      />
      {/* <ul>

        <li>
          <a href="#default-public-stage" className="text-gray-700 hover:text-cornflower text-sm block pl-2 py-1">Default public stage</a>
        </li>
      </ul> */}
    </div>
  );
};

export default MarkdownTOC;
