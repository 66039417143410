import React, { useCallback } from "react";
import { graphql } from "gatsby";
import LayoutDocs from "../components/LayoutDocs";
import LayoutLegal from "../components/LayoutLegal";
import SEO from "../components/seo";
import MarkdownContent from "../components/MarkdownContent";
import MarkdownTOC from "../components/MarkdownTOC";

const Template = (props) => {
  const { data } = props;
  const { markdownRemark } = data;

  const tocRenderer = useCallback(() => (
    <MarkdownTOC markdownRemark={markdownRemark} />
  ), [markdownRemark]);

  if (markdownRemark.frontmatter.slug.match(/\/legal/)) {
    return (
      <LayoutLegal rightSideRenderer={tocRenderer}>
        <SEO keywords={[`fashion`, `tech`, `open-source`]} title="About" />
  
        <MarkdownContent markdownRemark={markdownRemark} />
      </LayoutLegal>
    );    
  }

  return (
    <LayoutDocs rightSideRenderer={tocRenderer}>
      <SEO keywords={[`fashion`, `tech`, `open-source`]} title="About" />

      <MarkdownContent markdownRemark={markdownRemark} />
    </LayoutDocs>
  );
};

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
      html
      tableOfContents
    }
  }
`;
