import React from "react";

import "./index.css"

const MarkdownContent = props => {
  const { markdownRemark } = props;
  const { frontmatter, html } = markdownRemark;

  return (
    <section className="markdown-content">
      <h1 className="text-4xl mb-8">{frontmatter.title}</h1>
      <div
        className="docs-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </section>
  );
};

export default MarkdownContent;
